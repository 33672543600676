import React from 'react';
import Container from './Container';

const PLANL_GREEN = '#50d2c2';

export default function Footer() {
  return (
    <div
      css={{
        backgroundColor: PLANL_GREEN,
        color: 'white',
        padding: '25px 0 75px 0'
      }}
    >
      <Container
        customCSS={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap'
        }}
      >
        <div css={{ minWidth: 200, marginTop: 50 }}>
          <h1>Planl</h1>
          <p>Made with ♥ in Twente</p>
        </div>
        <div css={{ display: 'flex', alignItems: 'flex-end' }}>
          <ul css={{ listStyle: 'none', marginLeft: 0 }}>
            <li>
              <a href="/assets/privacybeleid.pdf" css={{ color: 'white', textDecoration: 'none' }}>
                Privacy beleid
              </a>
            </li>
            <li>
              <a href="/assets/gebruikersvoorwaarden.pdf" css={{ color: 'white', textDecoration: 'none' }}>
                Algemene voorwaarden
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
}
