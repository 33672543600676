import React from 'react';

// A feature component that contains a header + text/description and a graphical element
const Feature = ({ title, description, image, reverse, textStyle, graphicStyle, style }) => (
  <div
    css={{
      display: 'flex',
      alignItems: 'stretch',
      // Change the order of the graphic and text
      flexDirection: reverse ? 'row-reverse' : 'row',
      // space features 100px from each other
      margin: '100px 0',
      // Stack the graphic and text on smaller screens and decrease margin
      '@media (max-width: 768px)': {
        flexDirection: 'column',
        margin: '2em 0'
      },
      ...style
    }}
  >
    <div
      css={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        margin: '2em',
        // We accept a url to use as image (will be a background) or a custom graphic element (will be the child)
        background: typeof image === 'string' ? `url(${image}) center no-repeat` : 'none',
        backgroundSize: 'contain',
        minHeight: 400,
        // Decrease the hight on small screens
        '@media (max-width: 768px)': {
          minHeight: 200
        },
        ...graphicStyle
      }}
    >
      {typeof image !== 'string' && image}
    </div>
    <Feature.Text title={title} description={description} style={textStyle} />
  </div>
);

const Text = ({ title, description, style }) => (
  <div
    css={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      ...style
    }}
  >
    <h2>{title}</h2>
    {// We accept a string (and respect the new lines) or a custom description element
    typeof description === 'string' ? (
      <p css={{ whiteSpace: 'pre-line' }}>{description}</p>
    ) : (
      description
    )}
  </div>
);

// Renders features while alternating the reverse prop
const Group = ({ children }) => (
  <div>
    {React.Children.map(children, (child, index) =>
      React.cloneElement(child, { reverse: !!(index % 2) })
    )}
  </div>
);

Feature.Text = Text;
Feature.Group = Group;

export default Feature;
