import React from 'react';
import Container from './Container';

const PLANL_GREEN = '#50d2c2';

// TODO: Merge this with ./Menu.jsx
const Navbar = ({ children }) => (
  <div
    css={{
      backgroundColor: PLANL_GREEN,
      padding: '1em',
      textAlign: 'center',
      color: 'white',
      '& a': {
        color: 'white'
      }
    }}
  >
    <Container>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        {children}
      </div>
    </Container>
  </div>
);

const Header = ({ children }) => <h1 css={{ margin: 0 }}>{children}</h1>;

const Menu = ({ children }) => <div>{children}</div>;

const MenuItem = ({ children }) => (
  <div css={{ padding: '.25em', display: 'inline-block' }}>{children}</div>
);

Navbar.Header = Header;
Menu.Item = MenuItem;
Navbar.Menu = Menu;

export default Navbar;
