import React, { Component } from 'react';
import Button from '../Button';

// Encode so we can send it to netlify
const encode = data =>
  Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');

class InformationSystemForm extends Component {
  state = {
    leverancier: '',
    email: '',
    success: false,
    isSubmitting: false,
    missingFields: [],
    error: null
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();

    const missingFields = ['leverancier', 'email'].reduce(
      (missing, field) => (this.state[field] ? missing : missing.concat(field)),
      []
    );

    this.setState({ missingFields });

    if (missingFields.length) {
      return;
    }

    this.setState({ isSubmitting: true, error: null, success: false });

    const { leverancier, email } = this.state;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'information-system', leverancier, email })
    })
      .then(() => {
        this.setState({ success: true, isSubmitting: false });
      })
      .catch(error => {
        console.error(error);
        this.setState({ error, isSubmitting: false });
      });
  };

  render() {
    const { isSubmitting, leverancier, email, missingFields, error, success } = this.state;

    if (isSubmitting) {
      return <strong>Bezig de aanvraag te verwerken...</strong>;
    }

    if (success) {
      return <strong>Aanvraag verzonden, we houden je op de hoogte!</strong>;
    }

    return (
      <form
        name="information-system"
        data-netlify
        data-netlify-honeypot="bot-field"
        css={{
          '& label': {
            fontWeight: 'bold',
            lineHeight: '2em'
          },
          '& label.missing': {
            color: 'indianred'
          },
          '& input': {
            display: 'block',
            width: '100%',
            lineHeight: '1.2em',
            fontWeight: 'normal',
            backgroundColor: 'white',
            border: '1px solid rgb(222, 236, 255)',
            borderRadius: '.25em',
            padding: '.5em .75em',
            fontSize: '1em'
          },
          '& label.missing > input': {
            borderColor: 'indianred',
            color: 'indianred'
          }
        }}
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="contact" />

        <p hidden>
          <label htmlFor="bot-field">
            Vul dit niet in:
            <input id="bot-field" name="bot-field" />
          </label>
        </p>

        <p>
          <label
            htmlFor="leverancier"
            className={missingFields.indexOf('leverancier') > -1 ? 'missing' : ''}
          >
            De leverancier van je informatiesysteem
            <input
              id="leverancier"
              name="leverancier"
              placeholder="Leverancier"
              onChange={this.handleChange}
              value={leverancier}
            />
          </label>
        </p>

        <p>
          <label htmlFor="email" className={missingFields.indexOf('email') > -1 ? 'missing' : ''}>
            Je emailadres
            <input
              id="email"
              name="email"
              placeholder="Email"
              onChange={this.handleChange}
              value={email}
            />
          </label>
        </p>
        <Button primary>Houd mij op de hoogte</Button>
        <div css={{ color: 'indianred' }}>
          {missingFields.length > 0 &&
            'Je hebt niet all velden ingevuld. Ontbrekende velden zijn met rood gemarkeerd.'}
          {!!error && 'Er ging iets mis. Probeer het later nog eens.'}
        </div>
      </form>
    );
  }
}

export default InformationSystemForm;
