import Typography from 'typography';

// Todo: Use (vertical) rhythm for spacing bewtween components

const typography = new Typography({
  baseFontSize: '16px',
  baseLineHeight: 1.5,
  scaleRatio: 2.5,
  googleFonts: [
    {
      name: 'Nunito Sans',
      styles: ['400', '600', '700']
    }
  ],
  includeNormalize: true,
  headerFontFamily: [
    'Nunito Sans',
    'Avenir Roman',
    'Avenir Next',
    'Helvetica Neue',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif'
  ],
  bodyFontFamily: [
    'Nunito Sans',
    'Avenir Roman',
    'Avenir Next',
    'Helvetica Neue',
    'Segoe UI',
    'Helvetica',
    'Arial',
    'sans-serif'
  ],
  headerWeight: 700,
  bodyWeight: 400,
  overrideStyles: () => ({
    a: {
      color: 'black'
    }
  })
});

const { adjustFontSizeTo, rhythm } = typography;

export { rhythm, adjustFontSizeTo, typography as default };
