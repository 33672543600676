import React from 'react';
import Container from './Container';

const quoteSVG = require('../images/quote.svg');
const quoteFlippedSVG = require('../images/quote-flipped.svg');

const Testimonial = ({ story, name, description, position = 'left' }) => (
  <div
    css={{
      maxWidth: 700,
      marginTop: 40,
      // The testimonial can be rendered at a left or right position
      marginRight: position === 'left' ? 'auto' : 0,
      marginLeft: position === 'left' ? 0 : 'auto'
    }}
  >
    <p>
      <i>{`"${story}"`}</i>
    </p>

    <div css={{ fontWeight: 'bold' }}>{name}</div>

    <div css={{ color: 'rgba(0,0,0,.5)' }}>{description}</div>
  </div>
);

// Renders a group of testimonials including a playfull background
const Group = ({ children }) => (
  <div css={{ position: 'relative', margin: '100px 0' }}>
    {/* Upper quotation mark */}
    <img alt="" css={{ position: 'absolute', top: '3em', left: '1em', zIndex: 1 }} src={quoteSVG} />

    {/* Upper triangle */}
    <svg
      style={{
        display: 'block' /* without this there is a small whitespace visible */
      }}
      width="100%"
      viewBox="0 0 300 30"
    >
      <polygon points="0,0 300,30 0,30" style={{ fill: '#F0F7F8' }} />
    </svg>

    {/* Outer container for the background */}
    <div style={{ background: '#F0F7F8', padding: '4em 0', position: 'relative' }}>
      {/* Inner container */}
      <Container>
        <h1>Wat onze gebruikers zeggen</h1>
        {// Alternate the testimonials between left and right positions
        React.Children.map(children, (child, i) =>
          React.cloneElement(child, { position: i % 2 ? 'right' : 'left' })
        )}
      </Container>

      {/* Lower quotation mark */}
      <img alt="" css={{ position: 'absolute', bottom: 0, right: '1em' }} src={quoteFlippedSVG} />
    </div>

    {/* Lower triangle */}
    <svg
      style={{
        display: 'block' /* without this there is a small whitespace visible */
      }}
      width="100%"
      viewBox="0 0 300 30"
    >
      <polygon points="0,0 300,0 0,30" style={{ fill: '#F0F7F8' }} />
    </svg>
  </div>
);

Testimonial.Group = Group;

export default Testimonial;
