import React from 'react';
import Container from './Container';

const logo = require('../images/planl-logo.png');

const PLANL_GREEN = '#50d2c2';

// generate styles such based on breakpoints such that styling is always coherent
const defaultDegrees = 2;
const breakpoints = [1280, 1024, 768, 414];
const outerContainerBreakpointStyles = {};
const innerContainerBreakpointStyles = {};
breakpoints.forEach((width, index) => {
  outerContainerBreakpointStyles[`@media (max-width: ${width}px)`] = {
    transform: `skewY(-${defaultDegrees + index + 1})`
  };
  innerContainerBreakpointStyles[`@media (max-width: ${width}px)`] = {
    transform: `skewY(${defaultDegrees + index + 1})`
  };
});

const OuterContainer = ({ children }) => (
  <div
    css={{
      backgroundColor: PLANL_GREEN,
      backgroundRepeat: 'no-repeat',
      transform: `skewY(-${defaultDegrees}deg)`,
      transformOrigin: '0',
      width: '100%',
      paddingTop: '75px',
      overflow: 'hidden',
      ...outerContainerBreakpointStyles
    }}
  >
    {children}
  </div>
);

const InnerContainer = ({ children }) => (
  <div
    css={{
      color: 'white',
      padding: '1em',
      transform: `skewY(${defaultDegrees}deg)`,
      ...innerContainerBreakpointStyles
    }}
  >
    {children}
  </div>
);

const Hero = ({ children }) => (
  <div style={{ overflow: 'hidden' }}>
    <OuterContainer>
      <InnerContainer>
        <Container>
          <div
            css={{
              display: 'flex',
              flexWrap: 'wrap'
            }}
          >
            <div css={{ marginBottom: '1em', display: 'inline-block' }}>{children}</div>
            <div
              css={{
                display: 'inline-block',
                textAlign: 'center',
                flexGrow: 1,
                marginBottom: -65,
                alignSelf: 'flex-end'
              }}
            >
              <img
                alt=""
                src={logo}
                css={{
                  height: 325,
                  '@media (max-width: 920px)': {
                    height: 300
                  },
                  '@media (max-width: 768px)': {
                    height: 250
                  },
                  '@media (max-width: 414px)': {
                    height: 200
                  }
                }}
              />
            </div>
          </div>
        </Container>
      </InnerContainer>
    </OuterContainer>
  </div>
);

export default Hero;
