import React from 'react';

const KeyHighlight = ({ title, content, icon, style }) => (
  <div
    css={{
      padding: '1em',
      flex: 1,
      minWidth: 200,
      ...style
    }}
  >
    <img alt="" src={icon} css={{ height: 25 }} />
    <h2>{title}</h2>
    {typeof content === 'string' ? <p css={{ whiteSpace: 'pre-line' }}>{content}</p> : content}
  </div>
);

const Group = ({ children }) => (
  <div
    css={{
      '@media (max-width: 1280px)': {
        padding: '0 64px'
      },
      '@media (max-width: 1024px)': {
        padding: '0 56px'
      },
      '@media (max-width: 920px)': {
        padding: '0 48px'
      },
      '@media (max-width: 768px)': {
        padding: '0 40px',
        flexDirection: 'column'
      },
      '@media (max-width: 414px)': {
        padding: '0 28px'
      },
      display: 'flex'
    }}
  >
    {children}
  </div>
);

KeyHighlight.Group = Group;

export default KeyHighlight;
