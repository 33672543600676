import React from 'react';

const PLANL_GREEN = '#50d2c2';

const Button = ({ as: Component = 'button', style, primary, ...props }) => (
  <Component
    css={{
      border: 'none',
      backgroundColor: primary ? PLANL_GREEN : 'white',
      color: primary ? 'white' : 'black',
      padding: '10px 15px',
      display: 'inline-block',
      borderRadius: 5,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
      fontSize: '1em',
      fontWeight: '400',
      textDecoration: 'none',
      margin: '10px 0',
      cursor: props.disabled ? 'normal' : 'pointer',
      transition: 'box-shadow .2s ease-out',
      ':hover': {
        boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.4)'
      },
      ...style
    }}
    {...props}
  />
);

export default Button;
