import React from 'react';
import iPhone from '../images/iphone-7.png';

const IPhone = ({ children }) => (
  <div
    css={`
      background-image: url(${iPhone});
      background-size: contain;
      background-repeat: no-repeat;
      height: 500px;
      width: 245px;
      padding-top: 58px;
      padding-left: 17px;
    `}
  >
    <div
      css={{
        height: 375,
        width: 211
      }}
    >
      {children}
    </div>
  </div>
);

export default IPhone;
