import React from 'react';

const Intro = ({ children }) => (
  <p
    css={{
      fontSize: '1.35em',
      color: '#303030',
      textAlign: 'center',
      margin: '75px 0',
      lineHeight: '1.5em'
    }}
  >
    {children}
  </p>
);

export default Intro;
