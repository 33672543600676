import React from 'react';

const CallToActionStroke = ({ children }) => (
  <div
    css={{
      width: '100%',
      textAlign: 'center',
      backgroundColor: '#F0F7F8',
      padding: '40px 1em',
      margin: '100px 0'
    }}
  >
    {children}
  </div>
);

export default CallToActionStroke;
