// @flow
import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Container from '../components/Container';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Feature from '../components/Feature';
import Footer from '../components/Footer';
import Button from '../components/Button';
import Intro from '../components/Intro';
import KeyHighlight from '../components/KeyHighlight';
import CallToActionStroke from '../components/CallToActionStroke';
import Testimonial from '../components/Testimonial';
import IPhone from '../components/IPhone';
import demoMovie from '../images/demo.mp4';
import { adjustFontSizeTo, rhythm } from '../utils/typography';
import providerPage from '../images/provider-pagina.png';
import availabilityPage from '../images/beschikbaarheid-pagina.png';
import bestService from '../images/beste-service.png';
import directContact from '../images/direct-contact.png';
import growingGraph from '../images/groei.svg';
import InformationSystemForm from '../components/forms/InformationSystem';

const wandImage = require('../images/wand.png');
const lockImage = require('../images/lock.png');
const speedImage = require('../images/speed.png');
const reminderImage = require('../images/reminder.png');
const koppelingImage = require('../images/koppeling.svg');
const vervolgAfspraakImage = require('../images/vervolgafspraak.svg');
const onlineInschrijvingImage = require('../images/online-inschrijving.png');
const cameraImage = require('../images/camera.png');

const head = (
  <Helmet
    title="Online afspraken via Planl"
    meta={[
      {
        name: 'description',
        content:
          'De beste oplossing voor online afspraken. Planl maakt het contact tussen zorgverlener en patiënt makkelijker, gebruiksvriendelijker en toegankelijker voor zowel de zorgverlener als de patiënt.'
      },
      {
        name: 'keywords',
        content: 'Online afspraken maken,Afspraakmodule,Huisarts,Fysio,Fysiotherapeut,Enschede'
      }
    ]}
  />
);

const navbar = (
  <Navbar>
    <Navbar.Header>Planl</Navbar.Header>
    <Navbar.Menu>
      <Navbar.Menu.Item>
        <Link to="/">Patiënt?</Link>
      </Navbar.Menu.Item>
    </Navbar.Menu>
  </Navbar>
);

const hero = (
  <Hero>
    <div css={{ maxWidth: 512 }}>
      <h1 css={{ lineHeight: 1.5 }}>De beste oplossing voor online afspraken</h1>

      <p css={{ ...adjustFontSizeTo('1.2em'), lineHeight: 1.5 }}>
        Planl maakt het contact tussen zorgverlener en patiënt makkelijker, gebruiksvriendelijker en
        toegankelijker voor zowel de zorgverlener als de patiënt.
      </p>

      <Button as={Link} to="/contact">
        Neem contact op
      </Button>
      <Button
        as={Link}
        to="/demo"
        css={{ marginLeft: rhythm(1), color: 'white', textDecoration: 'none' }}
      >
        Naar de demo
      </Button>
    </div>
  </Hero>
);

const iphoneDemo = (
  <IPhone>
    <video
      autoPlay
      loop
      muted
      css={{
        height: '100%'
      }}
    >
      <source src={demoMovie} type="video/mp4" />
    </video>
  </IPhone>
);

const providerPageDescription = (
  <div>
    Hierop staat de belangrijkste informatie over je praktijk:
    <ul>
      <li>
        Jouw verhaal. Laat patiënten meer weten over je praktijk. Vertel wie je bent en waar je voor
        staat.
      </li>
      <li>
        De medewerkers en locaties waar patiënten terecht kunnen. Wij komen langs voor een
        foto-intake.
      </li>
      <li>
        Een knop voor het maken van afspraken. Hiermee kunnen patiënten direct een afspraak maken op
        jouw pagina. De knop kunnen we ook plaatsen op je eigen website.
      </li>
    </ul>
    Dit alles is door ons geoptimaliseerd voor de zoekmachines en smartphones.
  </div>
);

const koppelingContent = (
  <div>
    <p>
      Om de administratieve handelingen voor jouw nog verder te verminderen werken we aan een
      koppeling met allerlei informatiesystemen. Hierdoor worden afspraken straks direct verwerkt in
      het systeem dat je met jouw praktijk gebruikt. Benieuwd wanneer er een koppeling is voor jouw
      informatiesysteem?
    </p>
    <p>Laat je gegevens achter en ontvang een update als we de koppeling hebben gerealiseerd.</p>
    <InformationSystemForm />
  </div>
);

const ProviderLandingPage = () => (
  <div>
    {head}
    {navbar}
    {hero}
    <Container>
      <Intro>
        Geen telefoontjes meer die ten koste gaan van het leveren van zorg.
        <br />
        Geen dure, niet werkende of onveilige online oplossingen.
        <br />
        Gewoon 24 uur per dag, 7 dagen per week beschikbaar voor je patiënten.
      </Intro>

      <KeyHighlight.Group>
        <KeyHighlight
          icon={wandImage}
          title="Gebruiksvriendelijk"
          content="Planl is de gebruiksvriendelijkste oplossing voor online afspraken. Patiënten zien direct wanneer zij op de gewenste locatie bij de gewenste medewerker terecht kunnen.\n\nPlanl heeft naast een mobiele webapplicatie ook Android en iOS apps. Zo kunnen patiënten altijd en overal eenvoudig een afspraak maken via hun favoriete platform."
        />
        <KeyHighlight
          icon={lockImage}
          title="Veilig"
          content="Er worden steeds hogere eisen gesteld aan de beveiliging van privacygevoelige gegevens. Bij Planl is veiligheid daarom ook prioriteit nummer 1. Alle communicatie verloopt via veilige verbindingen en gegevens worden opgeslagen op goed beveildigde servers."
        />
        <KeyHighlight
          icon={speedImage}
          title="Volledige controle"
          content="Patiënten kunnen 24 uur per dag en 7 dagen per week een afspraak maken. Komt een afspraak ongelegen? Via het admin panel kun je eenvoudig een ander moment voorstellen of de afspraak afkeuren. De patiënt wordt automatisch op de hoogte gesteld."
        />
      </KeyHighlight.Group>
    </Container>
    <CallToActionStroke>
      <span css={{ ...adjustFontSizeTo('4em'), fontWeight: 'bolder' }}>200+</span>
      <p>
        Patiënten maken per praktijk maandelijks een afspraak via Planl
        <br />
        (en ze zijn super enthousiast)
      </p>
      <h3>Benieuwd hoe het werkt?</h3>
      <Button primary as={Link} to="/contact">
        Neem contact op
      </Button>
      <Button as={Link} to="/demo" css={{ marginLeft: rhythm(1), textDecoration: 'none' }}>
        Naar de demo
      </Button>
    </CallToActionStroke>

    <Container customCSS={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <div css={{ minWidth: 300, flex: 1, margin: '1em' }}>
        <h1>Echt een oplossing...</h1>
        <p>
          We bieden niet simpelweg een systeem aan, maar zijn er echt om jou te helpen met je
          digitale bereikbaarheid en het verminderen van administratieve lasten. We zijn voortdurend
          in overleg met eerstlijns zorgverleners, om zo goed mogelijk inzicht te krijgen in wat er
          in een praktijk zoal speelt en waar we onze expertise kunnen inzetten voor verbeteringen.
          We zijn graag een luisterend oor. Alleen samen kunnen we de zorg vernieuwen.
        </p>
      </div>
      <div css={{ minWidth: 300, flex: 1, margin: '1em' }}>
        <h1>…voor de gehele eerstelijn</h1>
        <p>
          Planl is er voor alle praktijken uit de eerstelijn en biedt daarmee een platform waarop
          patiënten voor alle eerstelijnszorg terecht kunnen. Planl is er dus onder andere voor
          huisartsen, tandartsen, fysiotherapeuten, oefentherapeuten, diëtisten, psychologen,
          ergotherapeuten, podotherapeuten, huidtherapeuten en logopedisten.
        </p>
      </div>
    </Container>

    <CallToActionStroke>
      <h3>Ook gehoord worden?</h3>
      <Button primary as={Link} to="/contact">
        Maak een afspraak met ons
      </Button>
    </CallToActionStroke>
    <Container>
      <h1 css={{ textAlign: 'center' }}>Wat we te bieden hebben</h1>

      <Feature.Group>
        <Feature
          title="Een gebruiksvriendelijke en veilige afspraakmodule"
          description="Bij Planl maken patiënten heel eenvoudig een afspraak. Ze geven hun voorkeuren op voor medewerkers en locaties en zien direct op welke tijden ze nog terecht kunnen.\n\nPas als patiënten de aanvraag voor een afspraak bevestigen hoeven ze in te loggen met hun telefoonnummer. De identiteit van een patiënt wordt gecontroleerd door een verificatiecode te sturen per SMS. Enkel de eerste keer hoeft een patiënt enkele persoonsgegevens op te geven. "
          image={iphoneDemo}
        />

        <Feature
          title="Een eigen pagina voor jouw praktijk"
          description={providerPageDescription}
          image={providerPage}
        />

        <Feature
          title="Vergroot je bereik en houd volledige controle over je agenda"
          description="Patiënten kunnen via Planl 24 / 7 een afspraak aanvragen op de tijden die jij instelt via ons admin panel. Op deze manier ben jij altijd bereikbaar voor je patiënten.\n\nDaarnaast bereik je via Planl ook nieuwe patiënten van andere praktijken die via ons platform een afspraak willen maken. Wij vullen dus voor jou je agenda!"
          image={availabilityPage}
        />

        <Feature
          title="De beste service richting je patiënten met onze apps en responsive website"
          description="Het is steeds belangrijker om in deze moderne tijden ook online de beste service richting je patiënten te leveren. Met de gebruiksvriendelijke apps voor iOS en Android, én de mobielvriendelijke webapplicatie van Planl ben je er zeker van dat je de beste service levert op het platform dat je patiënten tegenwoordig het meest gebruiken; de smartphone."
          image={bestService}
        />

        <Feature
          title="Direct contact met je patiënten"
          description="Kan een afspraak helaas niet doorgaan? Verwacht je meer tijd nodig te hebben voor de patiënt of plan je de patiënt liever in bij een andere medewerker? Via het admin panel kun je eenvoudig een afspraak wijzigen of afkeuren met een toelichting voor de patiënt. De toelichting wordt direct naar de patiënt gecommuniceerd via onze app en via een emailtje."
          image={directContact}
        />

        <Feature
          title="Betaal enkel voor wat je gebruikt"
          description="Wij zijn er om jou te helpen een betere service te verlenen en te besparen op administratieve lasten. Je betaalt bij ons daarom enkel voor de gemaakte afspraken. Geen verborgen kosten, geen peperdure maandelijkse abonnementen. Als wij niet leveren hoef jij niet te betalen."
          image={growingGraph}
        />
      </Feature.Group>
    </Container>
    <CallToActionStroke>
      <h3>Vrijblijvend uitproberen?</h3>
      <Button primary as={Link} to="/contact">
        Neem contact op
      </Button>
      <Button as={Link} to="/demo" css={{ marginLeft: rhythm(1), textDecoration: 'none' }}>
        Naar de demo
      </Button>
    </CallToActionStroke>
    <Container>
      <h1 css={{ textAlign: 'center' }}>In de maak</h1>
      <p css={{ textAlign: 'center' }}>
        Onze programmeurs zijn voortdurend bezig om jouw service te verbeteren. Hieronder een klein
        overzicht van de functionaliteiten die ze op dit moment aan het bouwen zijn.
      </p>
      <KeyHighlight.Group>
        <div css={{ flex: 1, minWidth: 200 }}>
          <KeyHighlight
            icon={reminderImage}
            title="Verminder no-shows door automatische herinneringen"
            content="Een foutje maken is menselijk, maar dat wil niet zeggen dat het niet vervelend is als patiënten niet komen opdagen voor hun afspraak. Doordat we patiënten helpen herinneren aan hun afspraak verminderen we no-shows."
          />
          <KeyHighlight
            icon={koppelingImage}
            title="Een koppeling met jouw informatiesysteem"
            content={koppelingContent}
          />
        </div>
        <div css={{ flex: 1, minWidth: 200 }}>
          <KeyHighlight
            icon={vervolgAfspraakImage}
            title="Plan eenvoudig een vervolgafspraak"
            content="Bij veel praktijken komen patiënten vaker langs, omdat ze bijvoorbeeld onder behandeling zijn. Wij zorgen ervoor dat het heel gemakkelijk wordt deze patiënten een vervolgafspraak te laten maken."
          />
          <KeyHighlight
            icon={onlineInschrijvingImage}
            title="Online inschrijvingen bij jouw praktijk en eConsults"
            content="Doordat wij één platform aanbieden voor alle eerstelijns zorgverleners is het logisch dat patiënten die bijvoorbeeld hun huisarts benaderen via ons systeem ook hun fysiotherapeut of tandarts zoeken via ons systeem. Zo brengen wij je nieuwe patiënten. Om te zorgen dat nieuwe patiënten direct een afspraak kunnen plannen zorgen wij dat ze zich online kunnen inschrijven. We hebben vaak al de benodigde gegevens.\n\nMocht een patiënt niet fysiek langs willen komen, maar wel een prangende vraag hebben, dan kunnen ze hun vragen stellen via een eConsult."
          />
          <KeyHighlight
            icon={cameraImage}
            title="Herhaalrecepten aanvragen met een foto"
            content="Geen gedoe met bandjes laten inspreken en weer afluisteren. Met onze module is het aanvragen van een herhaalrecept voor de patient zo gepiept met een foto van de verpakking. Zo kunnen patiënten direct een medicijn aanvragen als deze (bijna) op is."
          />
        </div>
      </KeyHighlight.Group>
    </Container>
    <Testimonial.Group>
      <Testimonial
        name="L. de Vlieger"
        description="Patiënt"
        story="Nog nooit zo makkelijk een afspraak kunnen maken. Alle stappen zijn erg duidelijk. Je kunt direct je huisarts en locatie selecteren en ziet vervolgens precies wanneer deze nog beschikbaar is. Ik zou willen dat dit ook zo makkelijk bij mijn andere zorgverleners kon."
      />
    </Testimonial.Group>
    <Container centered customCSS={{ marginBottom: 100 }}>
      <h1>Suggesties?</h1>
      <p>
        Mist er nog iets in het bovenstaande lijstje? Heb je andere vragen of suggesties? We
        luisteren graag naar je verhaal en komen graag langs om te kijken wat we nog meer voor je
        kunnen betekenen. Ook bij ons staat de deur altijd voor je open.
      </p>
      <Button primary as={Link} to="/contact">
        Neem contact met ons op
      </Button>
    </Container>
    <Footer />
  </div>
);

export default ProviderLandingPage;
